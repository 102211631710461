


/**
 * Required to support Web Animations `@angular/platform-browser/animations`.
 * Needed for: All but Chrome, Firefox and Opera. https://caniuse.com/#feat=web-animation
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.



/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.


import 'core-js/es6/reflect';
import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es7/reflect';

import 'zone.js';

import * as process from 'process';
import { Buffer } from 'buffer';

//window.process = process;
// (window as any).global = window;
// global.Buffer = global.Buffer || Buffer;
/***************************************************************************************************
 * APPLICATION IMPORTS
 */
